// Libraries
import React from 'react';
// Components
import Container from '../components/container';
import Layout from '../components/layout';

const Legal = (props) => {
  return (
    <Layout location={props.location}>
      <Container>
        <h2>Legal</h2>
        <b>
          This website is completely unofficial and in no way endorsed by Games
          Workshop.
        </b>
        <p>
          Cathay, Chaos, the Chaos device, the Chaos logo, Citadel, Citadel
          Device, Games Workshop, Games Workshop logo, GW, GWI, the GWI logo,
          Khorne, the Khorne device, Lord of Change, Nemesis Crown, Nemesis
          Crown logo, the Nemesis Crown device, Nurgle, the Nurgle device, Ork,
          Ork skull devices, Slaanesh, the Slaanesh device, Tzeentch, the
          Tzeentch device, Mordheim, the Mordheim logo, City of the Damned,
          Fanatic, the Fanatic logo, the Fanatic II logo, Warhammer, and all
          associated marks, names, races, race insignia, characters, vehicles,
          locations, units, illustrations and images from the Warhammer
          universe, are either ®, TM and/or © Games Workshop Ltd 2000-2021,
          variably registered in the UK and other countries around the world.
          Used without permission. No challenge to their status intended. All
          Rights Reserved to their respective owners.
        </p>
      </Container>
    </Layout>
  );
};

export default Legal;
